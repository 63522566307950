import React, {useEffect, useContext} from "react"
import Seo from "../components/utilities/Seo"
import {motion, useAnimation , usePresence} from "framer-motion"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import {SectionNavContext} from '../components/utilities/SiteContexts'
import {ourPartnersAndClientsHolder} from './pages.module.scss'
import RisingSpans from "../components/components/RisingSpans"
import ContactSection from "../components/components/ContactSection"
import Testimonials from "../components/components/Testimonials"



const pageVariants = {
  pre: {
    opacity:0,
    y:'2rem'
  },
  vis: {
    opacity:1,
    y:'0rem',
    transition: {
      type:'tween',
      duration:1,
    }
  },
  post: {
    opacity:0,
    y:'-2rem',
    transition: {
      type:'tween',
      duration:0.5,
    }
  }
}

const ulVariants = {
  pre: {
  },
  vis: {
    opacity:1,
    transition: {
      staggerChildren: 0.2,
      type:'tween',
      duration:1
    }
  },
  post: {
    opacity:0,
    transition: {
      staggerChildren: 0.2,
      type:'tween',
      duration:0.5,
    }
  }
}

const liVariants = {
  pre: {
    opacity:0,
    y:'2rem'
  },
  vis: {
    opacity:1,
    y:'0rem',
    transition: {
      type:'tween',
      duration:1,
    }
  },
  post: {
    opacity:0,
    y:'-2rem',
    transition: {
      type:'tween',
      duration:0.5,
    }
  }
}

const Partner = ({partner}) => {

  const image = getImage(partner.featuredImage?.node.localFile)

return (
  
    <a href={partner.testimonialInfo.link} title={partner.title} target="_blank" rel="noreferrer">
    <div className="partnerImage">{ image && <GatsbyImage image={image} alt={partner.title} objectFit={'contain'} /> }</div>
      <h4>{partner.title}</h4>
      <div className="info" dangerouslySetInnerHTML={{__html:partner.content}} />
    </a>
)
}



const OurPartnersAndClientsPage = () => {
  const pageControl = useAnimation()
  const sunControl = useAnimation()
  const [isPresent, safeToRemove] = usePresence()
  const [, setSectionNav] = useContext(SectionNavContext)


  const data = useStaticQuery(graphql`
  query PartnersQuery {
    allWpPartner {
      nodes {
        testimonialInfo {
          link
        }
        content
        menuOrder
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 58
                  placeholder: BLURRED
                  layout:CONSTRAINED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        slug
        title
      }
    }
    allWpTestimonial {
      nodes {
        content
        title
        slug
        menuOrder
        testimonialInfo {
          link
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 75
                  placeholder: BLURRED
                  layout:CONSTRAINED
                  formats: [WEBP, PNG]
                )
              }
            }
          }
        }
      }
    }
  }
  `)

  useEffect(() => {
    const introAnimation = async ()=> {
      await pageControl.start('vis')
      sunControl.start('vis')

    }

    introAnimation()

    setSectionNav([])
  }, [])

  useEffect( ()=>{

    const exitAnimation = async () => {
     sunControl.start('post')
      await pageControl.start('post')
      safeToRemove()
    }

    if(!isPresent) {
      exitAnimation()
    }
  },[pageControl, isPresent, sunControl, safeToRemove])


  const clients = data?.allWpTestimonial?.nodes
  const partners = data?.allWpPartner?.nodes




  return (
    <motion.article className={ourPartnersAndClientsHolder}  variants={pageVariants} animate={pageControl} initial="pre">

      <Seo title="Our Partners & Clients" description="CCS is proud to have worked with some of the biggest names in the renewables sector" />

      <div className="inner">
      <section className="introSection">
        <RisingSpans staggerChildren={0.3}><h1>Our Partners & Clients</h1></RisingSpans>
        <h4 className="subtitle">CCS is proud to have worked with some of the biggest names in the renewables sector</h4>
      </section>

      <section className="partners">
        <motion.ul  variants={ulVariants} animate="vis" initial="pre" exit="post">
        {
          partners?.map((partner,index) => <motion.li key={index}  variants={liVariants}> <Partner partner={partner} /> </motion.li>)
        }
        </motion.ul>
      </section>
      
      <section className="clients">
        <Testimonials testimonials={clients} />
      </section>
      
      <motion.div className="partnersBG" >
        <StaticImage
        src="../images/partners/partners-bg.jpg"
        alt="Blue Sky"
        />
      </motion.div>

      </div>



      <ContactSection isDark={false} title={"Interested in working with us?"} subTitle="Please get in touch."/>

    </motion.article>
  )
}

export default OurPartnersAndClientsPage
