import React, {useState} from 'react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { motion, AnimatePresence, useAnimation } from 'framer-motion'
import {testimonialsHolder} from './Testimonials.module.scss'


const liVariants = {
  pre: {
    opacity:0,
    y:'2rem'
  },
  vis: {
    y:0,
    opacity:1,
    transition:{
      duration:0.5
    }
  },
  post: {
    opacity:0,
    y:'-2rem',
    transition:{
      duration:0.5
    }
  }
}

const sunVariants = {
  pre: {
    pathLength: 0
  },
  vis: {
    pathLength: 1,
    transition:{
      duration:0.5
    }
  },
  post: {
    pathLength: 0,
    transition:{
      duration:0.5
    }
  }
}


const Testimonial = ({testimonial})=> {
  const image = getImage(testimonial.featuredImage?.node.localFile)

  return (
    <div className="testimonialInner">
          <div className="info" dangerouslySetInnerHTML={{__html:testimonial.content}} />
        
         <div className="testimonialImage">
         
           { image && <a href={testimonial.testimonialInfo.link} title={testimonial.title} target="_blank" rel="noreferrer">
             <GatsbyImage image={image} alt={testimonial.title} objectFit={'contain'} />
             </a> }
          </div>
        
    </div>
  )
}

export default function Testimonials({testimonials}) {
  const [showIndex, setShowIndex] = useState(0)
  const sunControl = useAnimation()


  const nextTestimonial = async () => {

    setShowIndex(showIndex => { 
    if(showIndex === testimonials.length - 1) {
      return 0
    } else {
      return showIndex + 1
    }
  })

      await sunControl.start('pre')
      await sunControl.start('vis')

  }

  const previousTestimonial = async () => {

    setShowIndex(showIndex => { 
      if(showIndex === 0) {
        return testimonials.length -1
      } else {
        return showIndex - 1
      }
    })
    await sunControl.start('pre')
    await sunControl.start('vis')

  }

  return (

    <div className={testimonialsHolder}>
      <ul>
        <AnimatePresence exitBeforeEnter>

        {
          testimonials.map((testimonial, index) => {
          if(showIndex === index) {
          return (
              <motion.li key={testimonial.slug} variants={liVariants} initial="pre" animate="vis" exit="post" onClick={()=>{setShowIndex(showIndex+1)}}>
                <Testimonial testimonial={testimonial} /> 
              </motion.li>
          )
          }
        })
        }
        </AnimatePresence>

      </ul>

      <button onClick={previousTestimonial} type="button" className="previousTestimonial" aria-label="nextTestimonial">
        <svg width="13" height="27" viewBox="0 0 13 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5 26L0.999999 12.5L12.5 1" stroke="#6E8B7C"/>
        </svg>
      </button>

      <button onClick={nextTestimonial} type="button" className="nextTestimonial" aria-label="nextTestimonial">
        <svg width="14" height="27" viewBox="0 0 14 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L12.5 14.5L1 26" stroke="#6E8B7C"/>
        </svg>
      </button>


      <motion.div className="sun">
        <svg className="dark" width="407" height="407" viewBox="0 0 407 407" fill="none" xmlns="http://www.w3.org/2000/svg">
          <motion.path animate={sunControl} initial="vis" variants={sunVariants} d="M405.5 203.5C405.5 315.062 315.062 405.5 203.5 405.5C91.9385 405.5 1.5 315.062 1.5 203.5C1.5 91.9385 91.9385 1.5 203.5 1.5C315.062 1.5 405.5 91.9385 405.5 203.5Z" stroke="currentColor" stroke-width="3"/>
        </svg>
      </motion.div>

      <svg className="quotes" width="56" height="43" viewBox="0 0 56 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.94688 39.0562C1.72813 35.6375 0 31.8031 0 25.5875C0 14.65 7.67812 4.84687 18.8437 0L21.6344 4.30625C11.2125 9.94375 9.175 17.2594 8.3625 21.8719C10.0406 21.0031 12.2375 20.7 14.3906 20.9C20.0281 21.4219 24.4719 26.05 24.4719 31.8031C24.4719 34.7039 23.3195 37.4859 21.2684 39.5371C19.2172 41.5883 16.4352 42.7406 13.5344 42.7406C11.9302 42.7266 10.3448 42.3936 8.8705 41.7611C7.39621 41.1286 6.06246 40.2091 4.94688 39.0562V39.0562ZM36.1969 39.0562C32.9781 35.6375 31.25 31.8031 31.25 25.5875C31.25 14.65 38.9281 4.84687 50.0937 0L52.8844 4.30625C42.4625 9.94375 40.425 17.2594 39.6125 21.8719C41.2906 21.0031 43.4875 20.7 45.6406 20.9C51.2781 21.4219 55.7219 26.05 55.7219 31.8031C55.7219 34.7039 54.5695 37.4859 52.5184 39.5371C50.4672 41.5883 47.6852 42.7406 44.7844 42.7406C43.1802 42.7266 41.5948 42.3936 40.1205 41.7611C38.6462 41.1286 37.3125 40.2091 36.1969 39.0562V39.0562Z" fill="#44A572"/>
      </svg>


    </div>
  )
}
